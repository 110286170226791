import { ApiSuccessResponse } from "../../_network/response";
import request from "../../_network/request";
import { LoginToken } from "./params";

const LoginSuperAdminByPhoneNumber = (phoneNumber: string, password: string): Promise<ApiSuccessResponse<LoginToken>> => {
  return request({
    url: `auth/login/superadmin/phone`,
    method: "POST",
    data: {
      phoneNumber: phoneNumber,
      password: password,
    },
  });
};

export const AuthApi = {
  LoginSuperAdminByPhoneNumber,
};
