import { Navigate, RouteObject, useNavigate, useRoutes } from "react-router-dom";
import { useAppSelector } from "../redux/hook";
import Login from "../layout/login";
import useProtectedRoute from "./routes";
import { Flowbite, Spinner, ThemeProps } from "flowbite-react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { flowbite_theme } from "./flowbite_theme";

const MainRoutes = () => {
  const { loading, modalShow } = useAppSelector((state) => state.globalState);
  const { token } = useAppSelector((state) => state.auth);
  const [protectedRoute] = useProtectedRoute();


  // company_context.
  let routes: RouteObject[] = [
    {
      path: "/",
      element: <Login />,
    },
    { path: "/404", element:  <div>Not Found</div>  },
    { path: "*", element:  <Navigate to="/" />  },
  ];

  let element = useRoutes(token ? protectedRoute : routes);
  // useEffect(() => {
  //   if (token) navigate("/transaction");
  // }, [token]);

  return (<Flowbite theme={flowbite_theme}>
      <div className={modalShow ? "overflow-hidden" : ""}>
        { loading && (
          <div className="fixed w-full h-screen flex items-center justify-center z-50 bg-slate-500/50">
            <Spinner></Spinner>
          </div>
        )}
        
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        {/* {token === null && <Navigate to="/" replace={true} />} */}
        {element}
      </div>
    </Flowbite>
  );
};

export default MainRoutes;
