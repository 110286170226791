import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/hook';
import { hideLoading, setHeader, showLoading } from '../../redux/global/global.action';
import 'react-datepicker/dist/react-datepicker.css';
import { Badge, Button, Label, Modal, Pagination, Spinner, Table, Textarea} from 'flowbite-react';
import useTransactions from './useTransactions';
import moment from 'moment-timezone';
import { NewCurrencyCode } from '_helper/currencyCode';
import { TransactionRequest } from '_services/transaction-api/params';
import { TransactionApi } from '_services/transaction-api';
import { toast } from 'react-toastify';
import { ApiErrorResponse } from '_network/response';
import ButtonAction from 'components/button-action';

interface ModalState {
  show: boolean;
  type: string;
  code: string;
  employeeName: string;
  total: number;
  reason: string;
}

const Transactions = () => {
  const dispatch = useAppDispatch();
  const { GetTransactions, transactions } = useTransactions();
  const [buttonLoading, setButtonLoading] = useState(false);
  useEffect(() => {
    dispatch(setHeader({ title: 'EWA Withdrawals Requiring Approval' }));
    dispatch(hideLoading());
  }, [dispatch]);

  const [currentpage, setCurrentPage] = useState(1);
  const size = 50;
  const [modalConfirm, setModalConfirm] = useState<ModalState>()
  const [rejectReason, setRejectReeason] = useState("");
  useEffect(() => {
    GetTransactions(currentpage, size);
  }, [currentpage])

  const resetModal = () => {
    setModalConfirm({
      show: false,
      type: "",
      code: "",
      employeeName: "",
      total: 0,
      reason: ""
    })
    setRejectReeason("");
  }

  const BadgeColor = (status: string) => {
    switch (status) {
      case "VALID":
        return "success"
      case "PENDING":
        return "warning"
      case "INVALID":
        return "failure"
      default:
        return ""
        break;
    }
  }

  const handleReject = async (code: string) => {
    setButtonLoading(true)
    try {
      await TransactionApi.Superadmin_RejectTransaction(code, rejectReason);
      toast.success('Reject data successfull');
      resetModal()
      GetTransactions(currentpage, size);
      setButtonLoading(false)
    } catch (error) {
      if (error as ApiErrorResponse) {
        toast.error((error as ApiErrorResponse).message);
      } else {
        console.log('Unknown error:', error);
        toast.error('Internal Error');
      }
      setButtonLoading(false)
    }
  }

  const handleApprove = async (code: string) => {
    setButtonLoading(true)
    try {
      await TransactionApi.Superadmin_ApproveTransaction(code);
      toast.success('Approve data successfull');
      resetModal()
      GetTransactions(currentpage, size);
      setButtonLoading(false)
    } catch (error) {
      if (error as ApiErrorResponse) {
        toast.error((error as ApiErrorResponse).message);
      } else {
        console.log('Unknown error:', error);
        toast.error('Internal Error');
      }
      setButtonLoading(false)
    }
  }

  return (
    <>
      <div className="mb-4">
        <Table striped={true}>
          <Table.Head className="!bg-primary !text-white">
            <Table.HeadCell> No </Table.HeadCell>
            <Table.HeadCell> Transaction Date </Table.HeadCell>
            <Table.HeadCell> Company Name </Table.HeadCell>
            <Table.HeadCell> Employee Id </Table.HeadCell>
            <Table.HeadCell> Employee Name </Table.HeadCell>
            <Table.HeadCell> Amount </Table.HeadCell>
            <Table.HeadCell> Fee </Table.HeadCell>
            <Table.HeadCell> Principal </Table.HeadCell>
            <Table.HeadCell> Bank Code </Table.HeadCell>
            <Table.HeadCell> Bank Account </Table.HeadCell>
            <Table.HeadCell> Account Name </Table.HeadCell>
            <Table.HeadCell> Bank Status </Table.HeadCell>
            <Table.HeadCell> Reason </Table.HeadCell>
            <Table.HeadCell> Action </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            { transactions?.transactions?.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan={12} className="text-center"> No Data </Table.Cell>
              </Table.Row>
            )}
            {
              transactions?.transactions?.map((x, i) => (
                <Table.Row
                  key={'transactions-' + i}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white"> {((currentpage - 1) * size + i + 1).toString()} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white"> {moment.tz(x.createdAt, 'Asia/Singapore').format('DD MMMM YYYY HH:mm')} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.companyName} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.employeeNumber} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.employeeName} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {NewCurrencyCode(x.countryCode)} {x.amount.toLocaleString()} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {NewCurrencyCode(x.countryCode)} {x.fee.toLocaleString()} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {NewCurrencyCode(x.countryCode)} {x.principal.toLocaleString()} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.bankCode} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.accountNumber} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.accountName} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap"> <Badge color={BadgeColor(x.bankStatus)}>{x.bankStatus}</Badge> </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.reason} </Table.Cell>
                  <Table.Cell className="flex items-center space-x-3">
                    <Button
                      color="info"
                      size="xs"
                      disabled={x.bankStatus !== "VALID" || moment(new Date()) > moment(x.periodEnd)}
                      onClick={() => {
                        setModalConfirm({
                          show: true,
                          type: "APPROVAL",
                          code: x.code,
                          employeeName: x.employeeName,
                          total: x.principal,
                          reason: x.reason ?? "",
                        })
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      color="failure"
                      size="xs"
                      onClick={() => {
                        setModalConfirm({
                          show: true,
                          type: "REJECT",
                          code: x.code,
                          employeeName: x.employeeName,
                          total: x.principal,
                          reason: x.reason ?? "",
                        })
                      }}
                    >
                      Reject
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
      </div>

      <Pagination
        currentPage={currentpage}
        totalPages={transactions ? transactions?.pagination?.lastPage : currentpage}
        onPageChange={(page: number) => {
          setCurrentPage(page);
        }}
      />

      <Modal
        show={modalConfirm?.show}
        onClose={() => {
          resetModal()
        }}
        >
        <Modal.Header>{modalConfirm?.type === "APPROVAL" ? "Approve" : "Reject"} Transaction Request </Modal.Header>
        <Modal.Body>
          <form>
            <div className="flex flex-col gap-4">
              <div>
                <div className="text-md font-bold self-center">
                  Are you sure to {modalConfirm?.type === "APPROVAL" ? "Approve" : "Reject"} this transaction?
                </div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Request Name" />
                </div>
                <div className="text-sm">{modalConfirm?.employeeName}</div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="total" value="Total" />
                </div>
                <div className="text-sm"> {modalConfirm?.total.toLocaleString()} </div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Request Reason" />
                </div>
                <div className="text-sm">{modalConfirm?.reason}</div>
              </div>
              {
                modalConfirm?.type !== "APPROVAL" && (
                  <div id="textarea">
                    <div className="mb-2 block">
                      <Label htmlFor="rejectReason" value="Alasan Penolakan" />
                    </div>
                    <Textarea
                      id="rejectReason"
                      placeholder="Input Reject Reason..."
                      rows={3}
                      value={rejectReason}
                      onChange={(e) => setRejectReeason(e.target.value)}
                    />
                  </div>
                )
              }
              <div className="flex flex-wrap gap-2 self-center">
                <ButtonAction color="success"  disabled={buttonLoading} onClick={() => {
                  modalConfirm?.type === "APPROVAL" ? handleApprove(modalConfirm?.code ?? "") : handleReject(modalConfirm?.code ?? "")
                }}>
                 { buttonLoading && ( <div className="mr-2"><Spinner /></div> )}Confirmation
                </ButtonAction>
                <ButtonAction
                  color="danger"
                  onClick={() => {
                    resetModal()
                  }}
                >
                  Cancel
                </ButtonAction>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Transactions;