export const NewCurrencyCode = (countryCode?: string) => {
  switch (countryCode){
      case 'NZ': return 'NZD'
      case 'CK': return 'NZD'
      case 'NU': return 'NZD'
      case 'PN': return 'NZD'
      case 'TK': return 'NZD'
      case 'AU': return 'AUD'
      case 'CX': return 'AUD'
      case 'CC': return 'AUD'
      case 'HM': return 'AUD'
      case 'KI': return 'AUD'
      case 'NR': return 'AUD'
      case 'NF': return 'AUD'
      case 'TV': return 'AUD'
      case 'AS': return 'EUR'
      case 'AD': return 'EUR'
      case 'AT': return 'EUR'
      case 'BE': return 'EUR'
      case 'FI': return 'EUR'
      case 'FR': return 'EUR'
      case 'GF': return 'EUR'
      case 'TF': return 'EUR'
      case 'DE': return 'EUR'
      case 'GR': return 'EUR'
      case 'GP': return 'EUR'
      case 'IE': return 'EUR'
      case 'IT': return 'EUR'
      case 'LU': return 'EUR'
      case 'MQ': return 'EUR'
      case 'YT': return 'EUR'
      case 'MC': return 'EUR'
      case 'NL': return 'EUR'
      case 'PT': return 'EUR'
      case 'RE': return 'EUR'
      case 'WS': return 'EUR'
      case 'SM': return 'EUR'
      case 'SI': return 'EUR'
      case 'ES': return 'EUR'
      case 'VA': return 'EUR'
      case 'GS': return 'GBP'
      case 'GB': return 'GBP'
      case 'JE': return 'GBP'
      case 'IO': return 'USD'
      case 'GU': return 'USD'
      case 'MH': return 'USD'
      case 'FM': return 'USD'
      case 'MP': return 'USD'
      case 'PW': return 'USD'
      case 'PR': return 'USD'
      case 'TC': return 'USD'
      case 'US': return 'USD'
      case 'UM': return 'USD'
      case 'VG': return 'USD'
      case 'VI': return 'USD'
      case 'HK': return 'HKD'
      case 'CA': return 'CAD'
      case 'JP': return 'JPY'
      case 'AF': return 'AFN'
      case 'AL': return 'ALL'
      case 'DZ': return 'DZD'
      case 'AI': return 'XCD'
      case 'AG': return 'XCD'
      case 'DM': return 'XCD'
      case 'GD': return 'XCD'
      case 'MS': return 'XCD'
      case 'KN': return 'XCD'
      case 'LC': return 'XCD'
      case 'VC': return 'XCD'
      case 'AR': return 'ARS'
      case 'AM': return 'AMD'
      case 'AW': return 'ANG'
      case 'AN': return 'ANG'
      case 'AZ': return 'AZN'
      case 'BS': return 'BSD'
      case 'BH': return 'BHD'
      case 'BD': return 'BDT'
      case 'BB': return 'BBD'
      case 'BY': return 'BYR'
      case 'BZ': return 'BZD'
      case 'BJ': return 'XOF'
      case 'BF': return 'XOF'
      case 'GW': return 'XOF'
      case 'CI': return 'XOF'
      case 'ML': return 'XOF'
      case 'NE': return 'XOF'
      case 'SN': return 'XOF'
      case 'TG': return 'XOF'
      case 'BM': return 'BMD'
      case 'BT': return 'INR'
      case 'IN': return 'INR'
      case 'BO': return 'BOB'
      case 'BW': return 'BWP'
      case 'BV': return 'NOK'
      case 'NO': return 'NOK'
      case 'SJ': return 'NOK'
      case 'BR': return 'BRL'
      case 'BN': return 'BND'
      case 'BG': return 'BGN'
      case 'BI': return 'BIF'
      case 'KH': return 'KHR'
      case 'CM': return 'XAF'
      case 'CF': return 'XAF'
      case 'TD': return 'XAF'
      case 'CG': return 'XAF'
      case 'GQ': return 'XAF'
      case 'GA': return 'XAF'
      case 'CV': return 'CVE'
      case 'KY': return 'KYD'
      case 'CL': return 'CLP'
      case 'CN': return 'CNY'
      case 'CO': return 'COP'
      case 'KM': return 'KMF'
      case 'CD': return 'CDF'
      case 'CR': return 'CRC'
      case 'HR': return 'HRK'
      case 'CU': return 'CUP'
      case 'CY': return 'CYP'
      case 'CZ': return 'CZK'
      case 'DK': return 'DKK'
      case 'FO': return 'DKK'
      case 'GL': return 'DKK'
      case 'DJ': return 'DJF'
      case 'DO': return 'DOP'
      case 'TP': return 'IDR'
      case 'ID': return 'IDR'
      case 'EC': return 'ECS'
      case 'EG': return 'EGP'
      case 'SV': return 'SVC'
      case 'ER': return 'ETB'
      case 'ET': return 'ETB'
      case 'EE': return 'EEK'
      case 'FK': return 'FKP'
      case 'FJ': return 'FJD'
      case 'PF': return 'XPF'
      case 'NC': return 'XPF'
      case 'WF': return 'XPF'
      case 'GM': return 'GMD'
      case 'GE': return 'GEL'
      case 'GI': return 'GIP'
      case 'GT': return 'GTQ'
      case 'GN': return 'GNF'
      case 'GY': return 'GYD'
      case 'HT': return 'HTG'
      case 'HN': return 'HNL'
      case 'HU': return 'HUF'
      case 'IS': return 'ISK'
      case 'IR': return 'IRR'
      case 'IQ': return 'IQD'
      case 'IL': return 'ILS'
      case 'JM': return 'JMD'
      case 'JO': return 'JOD'
      case 'KZ': return 'KZT'
      case 'KE': return 'KES'
      case 'KP': return 'KPW'
      case 'KR': return 'KRW'
      case 'KW': return 'KWD'
      case 'KG': return 'KGS'
      case 'LA': return 'LAK'
      case 'LV': return 'LVL'
      case 'LB': return 'LBP'
      case 'LS': return 'LSL'
      case 'LR': return 'LRD'
      case 'LY': return 'LYD'
      case 'LI': return 'CHF'
      case 'CH': return 'CHF'
      case 'LT': return 'LTL'
      case 'MO': return 'MOP'
      case 'MK': return 'MKD'
      case 'MG': return 'MGA'
      case 'MW': return 'MWK'
      case 'MY': return 'MYR'
      case 'MV': return 'MVR'
      case 'MT': return 'MTL'
      case 'MR': return 'MRO'
      case 'MU': return 'MUR'
      case 'MX': return 'MXN'
      case 'MD': return 'MDL'
      case 'MN': return 'MNT'
      case 'MA': return 'MAD'
      case 'EH': return 'MAD'
      case 'MZ': return 'MZN'
      case 'MM': return 'MMK'
      case 'NA': return 'NAD'
      case 'NP': return 'NPR'
      case 'NI': return 'NIO'
      case 'NG': return 'NGN'
      case 'OM': return 'OMR'
      case 'PK': return 'PKR'
      case 'PA': return 'PAB'
      case 'PG': return 'PGK'
      case 'PY': return 'PYG'
      case 'PE': return 'PEN'
      case 'PH': return 'PHP'
      case 'PL': return 'PLN'
      case 'QA': return 'QAR'
      case 'RO': return 'RON'
      case 'RU': return 'RUB'
      case 'RW': return 'RWF'
      case 'ST': return 'STD'
      case 'SA': return 'SAR'
      case 'SC': return 'SCR'
      case 'SL': return 'SLL'
      case 'SG': return 'SGD'
      case 'SK': return 'SKK'
      case 'SB': return 'SBD'
      case 'SO': return 'SOS'
      case 'ZA': return 'ZAR'
      case 'LK': return 'LKR'
      case 'SD': return 'SDG'
      case 'SR': return 'SRD'
      case 'SZ': return 'SZL'
      case 'SE': return 'SEK'
      case 'SY': return 'SYP'
      case 'TW': return 'TWD'
      case 'TJ': return 'TJS'
      case 'TZ': return 'TZS'
      case 'TH': return 'THB'
      case 'TO': return 'TOP'
      case 'TT': return 'TTD'
      case 'TN': return 'TND'
      case 'TR': return 'TRY'
      case 'TM': return 'TMT'
      case 'UG': return 'UGX'
      case 'UA': return 'UAH'
      case 'AE': return 'AED'
      case 'UY': return 'UYU'
      case 'UZ': return 'UZS'
      case 'VU': return 'VUV'
      case 'VE': return 'VEF'
      case 'VN': return 'VND'
      case 'YE': return 'YER'
      case 'ZM': return 'ZMK'
      case 'ZW': return 'ZWD'
      case 'AX': return 'EUR'
      case 'AO': return 'AOA'
      case 'AQ': return 'AQD'
      case 'BA': return 'BAM'
      case 'GH': return 'GHS'
      case 'GG': return 'GGP'
      case 'IM': return 'GBP'
      case 'ME': return 'EUR'
      case 'PS': return 'JOD'
      case 'BL': return 'EUR'
      case 'SH': return 'GBP'
      case 'MF': return 'ANG'
      case 'PM': return 'EUR'
      case 'RS': return 'RSD'
      case 'USAF': return 'USD'
  }
  return 'USD'
};