import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { hideLoading, showLoading } from 'redux/global/global.action';
import { useAppDispatch } from 'redux/hook';
import { ApiErrorResponse, UnknownError } from '_network/response';
import { TransactionApi } from '_services/transaction-api';
import { TransactionRequests } from '_services/transaction-api/params';

const useTransactions = (): {
  GetTransactions: (page: number, size: number) => void;
  transactions: TransactionRequests | undefined;
} => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<ApiErrorResponse>();
  const [transactions, setTransactions] = useState<TransactionRequests>();

  const GetTransactions = async (page: number, size: number) => {
    try {
      dispatch(showLoading());
      const res = await TransactionApi.Superadmin_GetPendingTransactions(page, size);
      setTransactions(res.data);
    } catch (error) {
      if (error as ApiErrorResponse) {
        setError(error as ApiErrorResponse);
        toast.error((error as ApiErrorResponse).message);
      } else {
        console.log('Unknown error:', error);
        setError(UnknownError(''));
        toast.error('Internal Error');
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  return {
    GetTransactions,
    transactions,
  };
};

export default useTransactions;
