import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hook";
import { hideLoading, setHeader } from "../../redux/global/global.action";
import { Badge, Label, Modal, Pagination, Select, Spinner, Table, Textarea, TextInput } from "flowbite-react";
import { FiSearch } from "react-icons/fi";
import ButtonAction from "components/button-action";
import useBankVerification from "./useBankVerification";
import { ApproveEmployeeBankReq, EmployeeBankDetail, RejectEmployeeBankReq } from "_services/employee-api/params";
import { EmployeeApi } from "_services/employee-api";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "_network/response";

const BankVerification = () => {
  const dispatch = useAppDispatch();
  const { GetPendingBank, employeeBanks } = useBankVerification();
  const [column, setColumn] = useState('employee_name');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [modalConfirm, setModalConfirm] = useState({
    id: 0,
    bankName: "",
    bankAccountNumber: "",
    accountName: "",
    show: false,
    type: ""
  })

  const [rejectReason, setRejectReeason] = useState("")
  const [bankData, setBankData] = useState<EmployeeBankDetail[]>();
  const [buttonLoading, setButtonLoading] = useState(false);

  const size = 50;

  useEffect(() => {
    dispatch(setHeader({ title: "Bank Account Validation" }));
    dispatch(hideLoading())  
  }, []);

  useEffect(() => {
    GetPendingBank(page, size, column, search)
  }, [page])

  useEffect(() => {
    const t = setTimeout(() => {
      GetPendingBank(page, size, column, search)
    }, 250);

    return () => clearTimeout(t);
  }, [search, column])

  const BadgeColor = (status: string) => {
    switch (status) {
      case "SUCCESS":
        return "success"
      case "PENDING":
        return "warning"
      case "INVALID":
        return "failure"
      default:
        return ""
        break;
    }
  }

  useEffect(() => {
    setBankData(employeeBanks?.employeeBanks)
  }, [employeeBanks?.employeeBanks])

  const resetModal = () => {
    setModalConfirm({
      id: 0,
      bankName: "",
      bankAccountNumber: "",
      accountName: "",
      show: false,
      type: ""
    })
  }

  const handleAccountName = (val: string, i: number) => {
    const list = [...bankData ?? []];
    list[i].accountName = val
    list[i].isError = false
    setBankData(list);
  }

  const handleValidateApprove = (i: number, x: EmployeeBankDetail) => {
    const list = [...bankData ?? []]
    if(list[i].accountName === "") {
      list[i].isError = true
    } else {
      list[i].isError = false
      setModalConfirm({
        id: x.id,
        show: true,
        bankName: x.bankName,
        bankAccountNumber: x.accountNumber,
        accountName: x.accountName,
        type:"APPROVAL"
      })
    }
    setBankData(list)
  }

  const approveBank = async (payload: ApproveEmployeeBankReq) => {
    setButtonLoading(true)
    try {
      await EmployeeApi.ApproveEmployeeBank(payload);
      toast.success('Approve bank successfull');
      resetModal()
      GetPendingBank(page, size, column, search)
      setButtonLoading(false)
    } catch (error) {
      if (error as ApiErrorResponse) {
        toast.error((error as ApiErrorResponse).message);
      } else {
        console.log('Unknown error:', error);
        toast.error('Internal Error');
      }
      setButtonLoading(false)
    }
  }

  const rejectBank = async (payload: RejectEmployeeBankReq) => {
    setButtonLoading(true)
    try {
      await EmployeeApi.RejectEmployeeBank(payload);
      toast.success('Reject bank successfull');
      resetModal()
      GetPendingBank(page, size, column, search)
      setButtonLoading(false)
    } catch (error) {
      if (error as ApiErrorResponse) {
        toast.error((error as ApiErrorResponse).message);
      } else {
        console.log('Unknown error:', error);
        toast.error('Internal Error');
      }
      setButtonLoading(false)
    }
  }

  const handleConfirmation = () => {
    if(modalConfirm?.type === "APPROVAL") {
      approveBank({ 
        id: modalConfirm?.id,
        accountName: modalConfirm?.accountName
      })
    } else {
      rejectBank({
        id: modalConfirm?.id,
        message: rejectReason
      })
    }
  }

  return (
    <>
      <div className="md:flex flex-row gap-2">
        <div className="mb-4 md:w-[14rem]">
          <Select
            placeholder={'Choose Filter'}
            defaultValue={column}
            onChange={(e: any) => {
              setColumn(e?.target?.value);
            }}
          >
            <option value={'employee_name'}>Employee Name</option>
            <option value={'bank_account_number'}>Bank Account Number</option>
          </Select>
        </div>
        <div className="w-full mb-4 md:w-[18rem]">
          <TextInput
            type="search"
            placeholder="Input Keywoard"
            sizing="md"
            icon={FiSearch}
            onChange={e => {
              setSearch(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div className="mb-4">
        <Table striped={true}>
          <Table.Head className="!bg-primary !text-white">
            <Table.HeadCell> No </Table.HeadCell>
            <Table.HeadCell> Company Name </Table.HeadCell>
            <Table.HeadCell> Employee Name </Table.HeadCell>
            <Table.HeadCell> Bank Name </Table.HeadCell>
            <Table.HeadCell> Bank Account Number </Table.HeadCell>
            <Table.HeadCell> Status </Table.HeadCell>
            <Table.HeadCell> Account Name </Table.HeadCell>
            <Table.HeadCell> Action</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {
              bankData?.length === 0 && (
                <Table.Row>
                  <Table.Cell colSpan={12} className="text-center"> No Data </Table.Cell>
                </Table.Row>
              )
            }
            {
              bankData?.map((x, i) => (
                <Table.Row key={i}>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {((page - 1) * size + i + 1).toString()} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.companyName} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.employeeName} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[150px]"> {x.bankName} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> {x.accountNumber} </Table.Cell>
                  <Table.Cell className="whitespace-nowrap"> 
                    <Badge color={BadgeColor(x.status)}>{x.status}</Badge>
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px]"> 
                    <TextInput value={x.accountName} onChange={(e) => handleAccountName(e.target.value, i)}/>
                    {
                      x.isError && (
                        <p className="mt-2 mb-2 text-sm text-red-600 dark:text-red-500">Please enter account name</p>
                      )
                    }
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap min-w-[200px] flex"> 
                    <div className="mr-2">
                      <ButtonAction color={"primary"} size="sm" onClick={() => {
                        handleValidateApprove(i, x)
                      }}>Approve</ButtonAction>
                    </div>
                    <div>
                      <ButtonAction color={"danger"} size="sm" onClick={() => {
                        setModalConfirm({
                          id: x.id,
                          show: true,
                          bankName: x.bankName,
                          bankAccountNumber: x.accountNumber,
                          accountName: x.accountName,
                          type:"REJECT"
                        })
                      }}>Reject</ButtonAction>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
        <Pagination
          currentPage={page}
          totalPages={employeeBanks ? employeeBanks?.pagination?.lastPage : page}
          onPageChange={(page: number) => {
            setPage(page);
          }}
        />
      </div>

      <Modal
        show={modalConfirm.show}
        onClose={() => {
          setModalConfirm({
            ...modalConfirm,
            show: false,
            type: ""
          })
        }}
        >
        <Modal.Header>{modalConfirm?.type === "APPROVAL" ? "Approve" : "Reject"} Bank Account </Modal.Header>
        <Modal.Body>
          <form>
            <div className="flex flex-col gap-4">
              <div>
                <div className="text-md font-bold self-center">
                  Are you sure to {modalConfirm?.type === "APPROVAL" ? "approve" : "reject"} this bank account?
                </div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Bank Name" />
                </div>
                <div className="text-sm">{modalConfirm?.bankName}</div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="total" value="Bank Account Number" />
                </div>
                <div className="text-sm">{modalConfirm?.bankAccountNumber}</div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Account Name" />
                </div>
                <div className="text-sm">{modalConfirm?.accountName}</div>
              </div>
              {
                modalConfirm?.type !== "APPROVAL" && (
                  <div id="textarea">
                    <div className="mb-2 block">
                      <Label htmlFor="rejectReason" value="Alasan Penolakan" />
                    </div>
                    <Textarea
                      id="rejectReason"
                      placeholder="Input Reject Reason..."
                      rows={3}
                      value={rejectReason}
                      onChange={(e) => setRejectReeason(e.target.value)}
                    />
                  </div>
                )
              }

              <div className="flex flex-wrap gap-2 self-center">
                <ButtonAction color="primary" disabled={buttonLoading} onClick={() => {handleConfirmation()}}>
                  { buttonLoading && ( <div className="mr-2"><Spinner /></div> )} Confirmation
                </ButtonAction>
                <ButtonAction
                  color="danger"
                  onClick={() => {
                    resetModal()
                    setRejectReeason("")
                  }}
                >
                  Cancel
                </ButtonAction>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BankVerification;

