import BankVerification from "pages/bank-verification";
import Transactions from "pages/transactions";
import DashboardLayout from "../layout/dashboard";
import Homepage from "../pages/homepage/homepage.page";
import PageNotFound from "../pages/other/404.page";

const useProtectedRoute = (): [any[]] => {
  const route = [
    {
      path: "/",
      element: <DashboardLayout />,
      name: "Dashboard",
      children: [
        { path: "/", element: <Homepage />, index: true, },
        { path: "/bank-verification", element: <BankVerification />},
        { path: "/transaction/ewa", element: <Transactions />},

        { path: "*", element: <PageNotFound />,  }

      ],
    },
  ];

  return [route];
};

export default useProtectedRoute;
