import { ApiSuccessResponse } from "../../_network/response";
import request from "../../_network/request";
import { TransactionRequests } from "./params";

const Superadmin_GetPendingTransactions = (page: number, size: number): Promise<ApiSuccessResponse<TransactionRequests>> => {
  return request({
    url: `transaction/superadmin/request?page=${page}&size=${size}`,
    method: "GET",
  });
};

const Superadmin_RejectTransaction = (Code: string, reason: string): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `transaction/superadmin/request/${Code}/reject`,
    method: "POST",
    data: {
      message: reason
    }
  });
}

const Superadmin_ApproveTransaction = (Code: string): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `transaction/superadmin/request/${Code}/approve`,
    method: "POST",
  });
}

export const TransactionApi = {
  Superadmin_GetPendingTransactions,
  Superadmin_RejectTransaction,
  Superadmin_ApproveTransaction,
};

