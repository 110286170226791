import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import CookieStorage from "./cookieStore";
import Cookies from "cookies-js";
import storage from "redux-persist/lib/storage";
import AuthReducer from "./auth/auth.reducer";
import GlobalReducer from "./global/global.reducer";

const persistConfig = {
  key: "newgajikureduxpersistconfig",
  storage: storage,
  blacklist: ["auth"],
};
const authPersistConfig = {
  key: "authonly",
  storage: new CookieStorage(Cookies, {}),
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthReducer),
  globalState: GlobalReducer,
});

export default persistReducer(persistConfig, rootReducer);
