import AuthActionTypes from "./auth.types";
import { batch } from "react-redux";
import GlobalActionTypes from "../global/global.types";
import { toast } from 'react-toastify';
import { AuthApi } from "../../_services/auth-api";
// import { Intent } from "@blueprintjs/core";

export const loginRedux = (username: string, password: string) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const login = await AuthApi.LoginSuperAdminByPhoneNumber(username, password);
      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: login.data.authToken,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        toast.success("Login Sukses")
        // dispatch({
        //   type: GlobalActionTypes.SHOW_ALERT,
        //   payload: {
        //     showAlert: true,
        //     alertMessage: "Login Success",
        //     alertType: "SUCCESS",
        //   },
        // });
      });
    } catch (error: any) {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN_FAILURE, payload: error });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        toast.error("Login Gagal. " + error.message)
        // dispatch({
        //   type: GlobalActionTypes.SHOW_ALERT,
        //   payload: {
        //     showAlert: true,
        //     alertMessage: error,
        //     alertType: "DANGER",
        //   },
        // });
      });
    }
  };
};

export const logoutRedux = () => {
  return async (dispatch: any) => {
    dispatch({ type: AuthActionTypes.LOGOUT });
  };
};
