import { Link, Outlet, useNavigate } from "react-router-dom";
import { logoutRedux } from "../redux/auth/auth.action";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import Logo from "../assets/images/logo.png";
import { Avatar, Dropdown, Navbar, Sidebar } from "flowbite-react";
import { ReactNode, useEffect, useState } from "react";
import React from "react";
import { ApiErrorResponse, UnknownError } from "../_network/response";
import { hideLoading, showLoading } from "../redux/global/global.action";
import { toast } from "react-toastify";
import { EmployeeApi } from "_services/employee-api";
import { EmployeeProfile } from "_services/employee-api/params";
// import { EmployeeAdminProfile } from "../_interfaces/employee-api.interfaces";
// import { EmployeeApi } from "../_services/employee-api.services";

const DashboardLayout = () => {
  const { title, hasBack, url } = useAppSelector((state) => state.globalState);
  const dispatch = useAppDispatch();
  const [
    employee_admin_profile,
    set_employee_admin_profile,
    employee_admin_profile_err,
  ] = useCurrentProfile();
  const navigate = useNavigate();
  const [sidebarStatus, setSidebarStatus] = useState(window.innerWidth >= 768);

  // https://tailwindcomponents.com/component/responsive-sidebar-tailwindcss
  return (
    <>
      <div
        className={
          "sidebar fixed top-0 bottom-0 lg:left-0 p-2 w-[300px] overflow-y-auto text-center bg-gray-900 z-50 " +
          (sidebarStatus ? "" : "hidden")
        }
      >
        <div className="text-gray-100 text-xl">
          <div className="p-2.5 mt-1 flex items-center">
            <img src={Logo} alt="gajiku" className="h-10" />
            <h1 className="font-bold text-gray-200 text-[15px] ml-3">
              Gajiku Dashboard
            </h1>
            <i
              className="bi bi-arrow-left-circle-fill cursor-pointer absolute right-[10px] bg-gray-900"
              onClick={() => {
                setSidebarStatus(false);
              }}
            ></i>
          </div>
          <div className="my-2 bg-gray-600 h-[1px]"></div>
        </div>

        <SidebarItem title="HomePage" to={"/"} icon={<i className={"bi bi-house-fill"}></i>}></SidebarItem>
        <SidebarItem title="Bank Account Validation" to={"/bank-verification"} icon={<i className={"bi bi-currency-dollar"}></i>}></SidebarItem>
        
        <SidebarItem title="Transaction" to={'#'} icon={<i className="bi bi-wallet"></i>}>
          <SidebarItem title="EWA Transactions" to={'/transaction/ewa'}></SidebarItem>
        </SidebarItem>
        {/* {
        company_settings.wage_request_enabled && (
        <SidebarItem title="Earned Wage Access" to={"#"} icon={<i className={"bi bi-wallet"}></i>}>
          <SidebarItem title="Daftar Transaksi" to={"#"}></SidebarItem>
          <SidebarItem title="Approval Transaksi" to={"#"}></SidebarItem>
        </SidebarItem> 
        )
        }
        
        {
        company_settings.production_tracking_enabled && (
        <SidebarItem title="Production Tracking" to={"#"} icon={<i className={"bi bi-box"}></i>}></SidebarItem>
        )
        } */}
        {/* 
        <SidebarItem title="Payroll" to={"#"} icon={<i className={"bi bi-bank"}></i>}></SidebarItem> 
        */}

        {/* <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-[#18b8ba] text-white" onClick={() => dispatch(logoutRedux())}>
          <i className="bi bi-box-arrow-in-right"></i>
          <span className="text-[15px] ml-4 text-gray-200 font-bold"> Logout </span>
        </div> */}
      </div>
      <div
        className={"w-100 h-screen " + (sidebarStatus ? "sm:pl-[300px] " : "")}
      >
        <Navbar
          fluid={true}
          rounded={false}
          border={true}
        >
          {sidebarStatus ? (
            <div></div>
          ) : (
            <Navbar.Toggle
              aria-hidden={false}
              style={{ display: "block" }}
              onClick={() => setSidebarStatus(!sidebarStatus)}
            />
          )}

          <div className="flex md:order-2">
            <Dropdown
              arrowIcon={true}
              inline={true}
              label={
                <>
                  <Avatar
                    alt="User settings"
                    img={""}
                    rounded={true}
                  />
                  <div className="ml-2 self-start text-left">
                    <div className="text-sm">{employee_admin_profile.name}</div>
                    <div className="font-bold text-sm">
                      {employee_admin_profile.employeeNumber}
                    </div>
                  </div>
                </>
              }
            >
              <Dropdown.Item onClick={() => dispatch(logoutRedux())}>
                {" "}
                Logout
              </Dropdown.Item>
            </Dropdown>
          </div>
        </Navbar>
        <div className="p-10">
          <div className="flex flex-row gap-4 items-end mb-10">
            {hasBack && (
              <p
                className="text-base font-bold hover:text-blue-400 cursor-pointer"
                onClick={() => {
                  url != "" ? navigate(url, { replace: true }) : navigate(-1);
                }}
              >
                &larr; Back
              </p>
            )}
            <h1 className="text-3xl font-semibold">{title}</h1>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;

const SidebarItem = (props: {
  title: string;
  to: string;
  icon?: JSX.Element;
  children?: ReactNode;
}) => {
  const [submenu, setSubmenu] = useState(false);

  if (React.Children.count(props.children) == 0) {
    return (
      <Link
        to={props.to}
        className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-[#18b8ba] text-white"
      >
        {props.icon}
        <span className="text-[15px] ml-4 text-gray-200 font-bold">
          {props.title}
        </span>
      </Link>
    );
  }

  return (
    <>
      <div>
        <div
          className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-[#18b8ba] text-white"
          onClick={() => {
            setSubmenu(!submenu);
          }}
        >
          {props.icon}
          <div className="flex justify-between w-full items-center">
            <span className="text-[15px] ml-4 text-gray-200 font-bold">
              {props.title}
            </span>
            <span className="text-sm rotate-180" id="arrow">
              <i
                className={submenu ? "bi bi-chevron-down" : "bi bi-chevron-up"}
              ></i>
            </span>
          </div>
        </div>
        <div className={submenu ? "" : "hidden"}>
          <div className="ml-4">{props.children}</div>
        </div>
      </div>
    </>
  );
};


function useCurrentProfile(): [
  EmployeeProfile,
  React.Dispatch<React.SetStateAction<EmployeeProfile>>,
  ApiErrorResponse | undefined
] {
  const [result, setResult] = useState<EmployeeProfile>({
    name: "",
    employeeNumber: "",
    phoneNumber: "",
    employeeEmail: "",
    joinDate: "",
    bankCode: "",
    bankName: "",
    bankAccountNumber: "",
    bankAccountName: "",
    bankAccountStatus: "",
    bankAccountMessage: "",
  });
  const [error, setError] = useState<ApiErrorResponse>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetch() {
      try {
        dispatch(showLoading());
        var res =
          await EmployeeApi.GetCurrent();
        setResult(res.data);
      } catch (error) {
        if (error as ApiErrorResponse) {
          setError(error as ApiErrorResponse);
          toast.error((error as ApiErrorResponse).message);
        } else {
          console.log("Unknown error:", error);
          setError(UnknownError(""));
          toast.error("Internal Error");
        }
      } finally {
        dispatch(hideLoading());
      }
    }
    fetch();
  }, []);

  return [result, setResult, error];
}