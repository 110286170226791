import { Button, ButtonProps } from "flowbite-react";
import { useEffect, useState } from "react";

const ButtonAction = (props: ButtonProps) => {
    const [style, setStyle] = useState(
        {backgroundColor: "#18b8ba", color: "#ffffff"}
  );

    useEffect(() => {
        switch (props.color) {
            case "primary":
                setStyle({backgroundColor: "#18b8ba", color: "#ffffff"})
                break;
            case "warning":
                setStyle({backgroundColor: "#fbbf24", color: "#ffffff"})
                break;
            case "danger":
                setStyle({backgroundColor: "#d41c1c", color: "#ffffff"})
                break;
            default:
                setStyle({backgroundColor: "#18b8ba", color: "#ffffff"})
                break;
        }
    }, [props.color]);

  return (
    <Button
        style={style}
        {...props}
    >      
      {props.children}
    </Button>
  );
};

export default ButtonAction;
