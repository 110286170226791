import { useEffect } from "react";
import { useAppDispatch } from "../../redux/hook";
import { hideLoading, setHeader } from "../../redux/global/global.action";


const Homepage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setHeader({ title: "Welcome on Superadmin Dashboard" }));
    dispatch(hideLoading())  
  }, []);

  return (
    <>
      <div></div>
    </>
  );
};

export default Homepage;

