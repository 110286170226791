import { loginRedux } from "../redux/auth/auth.action";
import { useAppDispatch } from "../redux/hook";
import imageBanner from "../assets/images/login-banner.svg";
import logoLogin from "../assets/images/logo-login.png";
import { useEffect, useState } from "react";
import { setHeader } from "../redux/global/global.action";

const Login = () => {
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const dispatch = useAppDispatch();
  
  const login = (e: any) => {
    e.preventDefault();
    dispatch(loginRedux(form.username, form.password));
  };

  const handleChange = (e: any) => {
    setForm((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    dispatch(setHeader({ title: "Login to Admin Dashboard" }));
  }, []);
  return (
    <div className="w-full h-screen flex flex-row">
      <div className="w-[65%] h-screen flex flex-col items-center justify-center gap-4">
        <img src={logoLogin} alt="logo" className="w-1/6" />
        <img src={imageBanner} alt="logo" className="w-2/5" />
        <div className="text-2xl text-[#18b8ba] text-center font-semibold tracking-wide">
          Gajiku adalah solusi bagi tenaga kerja
        </div>
        <div className="text-sm text-[#18b8ba] text-center tracking-wide">
          untuk dapat mengakses gaji mereka tanpa membebankan perusahaan
        </div>
      </div>
      <div className="w-[35%] h-screen bg-[#18b8ba] flex flex-col justify-center items-center gap-10">
        <div className="flex flex-col justify-center items-center gap-4">
          <div className="text-white text-center text-xl font-semibold">
            Welcome
          </div>
          <div className="text-white text-center text-sm">
            Sign in to gajiku admin dashboard
          </div>
        </div>
        <form className="flex flex-col gap-4" onSubmit={login}>
          <div className="flex flex-col w-80 gap-2">
            <label className="text-white text-sm">Username</label>
            <input
              type="text"
              placeholder="Username"
              name="username"
              className="bg-white rounded-md h-10 p-2"
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col w-80 gap-2">
            <label className="text-white text-sm">Password</label>
            <input
              type="password"
              placeholder="Password"
              name="password"
              className="bg-white rounded-md h-10 p-2"
              onChange={handleChange}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full h-10 rounded-md bg-[#313b6e] text-white mt-10"
          >
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
