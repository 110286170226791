import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { hideLoading, showLoading } from 'redux/global/global.action';
import { useAppDispatch } from 'redux/hook';
import { ApiErrorResponse, UnknownError } from '_network/response';
import { EmployeeApi } from '_services/employee-api';
import { EmployeeBankDetails } from '_services/employee-api/params';

const useBankVerification = (): {
  GetPendingBank: (page: number, size: number, searchBy: string, keyword: string) => void;
  employeeBanks: EmployeeBankDetails | undefined;
} => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<ApiErrorResponse>();
  const [employeeBanks, setEmployeeBanks] = useState<EmployeeBankDetails>();

  const GetPendingBank = async (page: number, size: number, searchBy: string, keyword: string) => {
    try {
      dispatch(showLoading());
      const res = await EmployeeApi.GetPendingBankAccounts(page, size, searchBy, keyword);
      setEmployeeBanks(res.data);
    } catch (error) {
      if (error as ApiErrorResponse) {
        setError(error as ApiErrorResponse);
        toast.error((error as ApiErrorResponse).message);
      } else {
        console.log('Unknown error:', error);
        setError(UnknownError(''));
        toast.error('Internal Error');
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  return {
    GetPendingBank,
    employeeBanks,
  };
};

export default useBankVerification;
