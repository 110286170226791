import { ApiSuccessResponse } from "../../_network/response";
import request from "../../_network/request";
import { ApproveEmployeeBankReq, EmployeeBankDetails, EmployeeProfile, RejectEmployeeBankReq } from "./params";

const GetCurrent = (): Promise<ApiSuccessResponse<EmployeeProfile>> => {
  return request({
    url: `employee/current`,
    method: "GET",
  });
};

const GetPendingBankAccounts = (page: number, size: number, searchBy: string, keyword: string): Promise<ApiSuccessResponse<EmployeeBankDetails>> => {
  return request({
    url: `employee/superadmin/bank/pending?page=${page}&size=${size}&searchBy=${searchBy}&keyword=${keyword}`,
    method: "GET",
  });
}

const ApproveEmployeeBank = (payload: ApproveEmployeeBankReq): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `employee/superadmin/bank/${payload?.id}/approve`,
    method: "POST",
    data: {
      accountName: payload?.accountName
    }
  });
}

const RejectEmployeeBank = (payload: RejectEmployeeBankReq): Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `employee/superadmin/bank/${payload?.id}/reject  `,
    method: "POST",
    data: {
      message: payload?.message
    }
  });
}

export const EmployeeApi = {
  GetCurrent,
  GetPendingBankAccounts,
  ApproveEmployeeBank,
  RejectEmployeeBank,
};

